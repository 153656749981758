import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

export default function PageTracking() {
    const location = useLocation();
    useEffect(() => {
        ReactGA.initialize('UA-6564620-6', { gaOptions: { cookieFlags: "SameSite=None;Secure" } });
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
    return null;
}